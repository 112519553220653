jQuery( document ).ready(function($) {

  const allCardsLength = document.querySelectorAll('.card').length

  // play this card
  const playThisCard = (cardId) => {
    const updateCard = document.querySelector(`#${cardId}`)
    const updatedOldImageSrc = updateCard.querySelector('.card-old').getAttribute('src')
    const updatedNewImageSrc = updateCard.querySelector('.card-new').getAttribute('src')
    const updatedCreator = updateCard.querySelector('.card-credit').innerHTML
    
    const featuredCard = document.querySelector('.card-featured')
    const CurrentOldImage = featuredCard.querySelector('.card-old')
    const CurrentNewImage = featuredCard.querySelector('.card-new')
    const CurrentCreator = featuredCard.querySelector('.card-credit')
    
    CurrentOldImage.setAttribute('src', updatedOldImageSrc)
    CurrentNewImage.setAttribute('src', updatedNewImageSrc)
    featuredCard.setAttribute('id', cardId)
    CurrentCreator.innerHTML = updatedCreator

    window.history.replaceState(null, null, `#${cardId}`);
  }

  // go to next card
  const goToNext = () => {
    const currentId = document.querySelector('.card-featured').getAttribute('id').replace('card-','');
    let nextId
    if ( parseInt(currentId) === allCardsLength ) {
      nextId = 1
    } else {
      nextId = parseInt(currentId) + 1
    }
    playThisCard(`card-${nextId}`)
    setTimeout(() => {
      $('.card-featured').addClass('flipped')
    }, "1000")
    setTimeout(() => {
      $('.card-featured').removeClass('flipped')
    }, "3000")
  }

  // go to prev card
  const goToPrev = () => {
    const currentId = document.querySelector('.card-featured').getAttribute('id').replace('card-','');
    let prevId
    if ( parseInt(currentId) === 1 ) {
      prevId = allCardsLength
    } else {
      prevId = currentId - 1
    }
    playThisCard(`card-${prevId}`)
    setTimeout(() => {
      $('.card-featured').addClass('flipped')
    }, "1000")
    setTimeout(() => {
      $('.card-featured').removeClass('flipped')
    }, "3000")
  }

  // open popup
  const openPopup = (e) => {
    $('.card-popup').addClass('active')
    $(this).toggleClass('flipped')

    const cardId = e.currentTarget.getAttribute('id')
    playThisCard(cardId)

    setTimeout(() => {
      $('.card-featured').addClass('flipped')
    }, "1000")
    setTimeout(() => {
      $('.card-featured').removeClass('flipped')
    }, "3000")

    document.onkeydown = function(e) {
      switch(e.which) {
          case 37: // left
          goToPrev()
          break;
  
          case 39: // right
          goToNext()
          break;
  
          case 27: // right
          cardClose()
          break;
  
          default: return; // exit this handler for other keys
      }
      e.preventDefault(); // prevent the default action (scroll / move caret)
    };
  }

  // close popup
  const cardClose = () => {
    $('.card').removeClass('flipped')
    $('.card-popup').removeClass('active')
    $('.card-featured').removeClass('flipped')
  }

  // events
  $('.card').click(openPopup)
  $('.prev').click(goToPrev)
  $('.next').click(goToNext)
  $('.close').click(cardClose)
  $('.card-featured, .card-mobile').click(function(){
    $(this).toggleClass('flipped')
  })

});